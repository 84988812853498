@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.file-upload-holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    text-align: left;
    

    .label {
        font-weight: 600;
        margin-bottom: 0;
        line-height: 1.2rem;
    }

    .compact {
        gap: 0.2rem !important;
    }

    .upload-btn-holder {
        min-width: 12rem;
    }

    .upload-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        width: auto;
        padding: 0.5rem 1rem;

        border: 2px solid global.$primary-color;
        color: global.$primary-color;
        border-radius: 0.5rem;

        cursor: pointer;
    }

    .btn-off {
        border-color: global.$mid-grey-2;
        color: global.$mid-grey-2;
    }

    .remove-btn {
        color: global.$primary-color;
        cursor: pointer;
    }

    .file-name-holder {
        max-width: 100%;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    input[type="file"] {
        visibility: hidden;
        width: 0px;
        height: 0px;
        overflow: hidden;
        margin: 0;
        padding: 0;
    }

    .file-val-holder {
        @include forms.form-field-style;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
}
