@use "../../scss-fragments/global";

.textarea { 
    width: 100%;
    height: 75px;
}

.grid-item {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    height: 100%;
}
.grid-item.row-new-status-colour {
    background-color: global.$primary-light-color;
}
.grid-item.row-inprogress-status-colour {
    background-color: global.$secondary-color;
}
.grid-item.row-onhold-status-colour {
    background-color: global.$warning-light-color;
}
.grid-item.row-ignored-status-colour {
    background-color: global.$mid-grey-2;
}
.grid-item.row-complete-status-colour {
    background-color: global.$success-bg-color;
}

/* .grid-item-long {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */



.new-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$primary-light-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$primary-light-color;
    }
}
.inprogress-status-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$secondary-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$secondary-color;
    }
}
.onhold-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$warning-light-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$warning-light-color;
    }
}
.ignored-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$mid-grey-2;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$mid-grey-2;
    }
}
.complete-status-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$success-bg-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$success-bg-color;
    }
}



.high-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$error-bg-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$error-bg-color;
    }
}
.medium-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$warning-color!important;
    }
    .top-row {
        color: #fff;
        background-color: global.$warning-color!important;
    }
}
.low-priority-colour {
    .drop-down-list {
        color: #fff;
        background-color: global.$success-color;
    }
    .top-row {
        color: #fff;
        background-color: global.$success-color;
    }
}
.wishlist-priority-colour {
    .drop-down-list {
        color: global.$primary-color;
        background-color: global.$secondary-bg-color;
    }
    .top-row {
        color: global.$primary-color;
        background-color: global.$secondary-bg-color;
    }
}
.edit-user-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;

    & h2 {
        margin-bottom: 1rem;
    }

    .s-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 2rem;
        row-gap: 0;
    }

    .column-cell {
        display: flex;
        flex-direction: column;
    }

    .no-gap {
        gap: 0;
    }

    .small-gap {
        gap: 1rem;
    }

    .s-profile-pic {
        position: relative;
        flex-shrink: 1;

        .icon-overlay {
            position: absolute;
            right: 0.6rem;
            bottom: 0.6rem;
            width: 2rem;
            height: 2rem;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .pp-style {
        border-color: global.$mid-grey-2;
    }

    .s-edit-icon {
        color: global.$primary-color;
        cursor: pointer;
    }

    .pp-edit {
        background-color: #FFF;
        border: 0px solid global.$primary-color;
        border-radius: 50em;
    }

    .tw-ppic-icon {
        width: 100%;
    }
}

textarea#newNote {
    height:50px
}
.existing-notes {
    max-height:100px;
    overflow-y: auto;
    .note {
        color: global.$mid-grey-1;
        font-size: 0.9rem;
    }
}

.edit-bugReport-container {
    display: flex;
    flex-direction: row; // Ensure the reply column appears to the right
    align-items: flex-start; // Align items at the top
    width: 100%; // Let the parent container control the width
    transition: width 0.3s ease; // Smooth transition when the panel grows


    &.with-reply-column .popup-panel {
        flex-basis: 800px; // Expand to fit the reply window
    }
    .popup-panel {
        display: flex; // Enable flex layout for the panel
        flex-direction: row; // Arrange children horizontally
        flex-basis: 500px; // Default width when the reply window is not open
        max-width: 800px; // Ensure it doesn't exceed the maximum width
        transition: flex-basis 0.3s ease; // Smooth transition for resizing
    }

    
    .columns-container {
        display: flex; // Enable flex layout for the columns
        flex-direction: row; // Arrange columns horizontally
        align-items: flex-start; // Align items at the top
        width: 100%; // Ensure the container takes up the full width
        gap: 1rem; // Add spacing between the columns
    }

    .form-holder {
        flex: 0 0 460px; // Fixed width for the left column
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-bottom: 5rem; // Add spacing for the update button
        position: relative; // Ensure the submit button can be positioned absolutely

        .submit-btn {
            position: absolute;
            bottom: 1rem; // Position the button at the bottom of the column
            min-width: 120px;
        }

        .existing-notes {
            max-height: 150px;
            overflow-y: auto;
            margin-bottom: 2rem; // Add extra spacing at the bottom
            padding-right: 0.5rem;

            .note {
                color: global.$mid-grey-1;
                font-size: 0.9rem;
                margin-bottom: 0.25rem;
                line-height: 1.4;
            }

            .note-replies {
                font-size: 0.9rem; // Match font size with notes
                color: global.$primary-color;
                cursor: pointer;
                margin: 0.1rem 0 1rem 1rem; // Reduce space above, increase below
                text-decoration: underline;

                &:hover {
                    color: global.$secondary-color;
                }
            }
        }
    }

    .reply-column {
        flex: 0 0 300px; // Fixed width for the reply column
        border-left: 1px solid global.$mid-grey-2;
        padding-left: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 30px;

        .reply-window-note {
            font-size: 1.1rem;
            padding: 1rem;
            background-color: global.$secondary-bg-color;
            border-radius: 4px;
            margin-bottom: 1rem;
        }

        .reply-window-reply {
            padding: 0.75rem 1rem;
            margin-left: 1rem;
            border-left: 2px solid global.$mid-grey-2;
            margin-bottom: 0.5rem;
        }

        .reply-input {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .textarea {
                width: 100%;
                height: 75px;
            }

            button {
                align-self: flex-end;
            }
        }

        .close-reply-btn {
            display: inline-flex; // Align text and icon horizontally
            align-items: center;
            gap: 0.25rem; // Add spacing between text and icon
            cursor: pointer;

            .close-reply-text {
                color: global.$primary-color; // Use link color
                font-size: 0.9rem; // Match font size with notes
                text-decoration: underline; // Underline only the text

                &:hover {
                    color: global.$secondary-color; // Change color on hover
                }
            }

            .close-icon {
                font-size: 1.4rem; // Slightly larger icon size for better visibility
                line-height: 1;
                color: global.$primary-color; // Match the text color
                text-decoration: none; // Ensure the "X" icon is not underlined

                &:hover {
                    color: global.$secondary-color; // Change color on hover
                }
            }
        }
    }
}

.add-reply-button {
    width: 100px !important;
}