@use "../../../scss-fragments/global";
@use "../../../scss-fragments/forms";

.font-selector-content {
    #font-picker {
        width: 100%;
        box-shadow: none;

        button {
            @include forms.form-field-style;
            cursor: pointer;
            padding: 0 1rem;
        }

        .font-list {
            button {
                border-radius: 0;

                &:hover {
                    background-color: global.$primary-mid-color !important;
                    color: #000000;
                }
            }

            .active-font {
                background-color: global.$primary-mid-color !important;
                color: #000000;
            }

            border-radius: 1rem;
        }

        .finished::before {
            border-top: 6px solid global.$dark-grey-3 !important;
        }
    }

    .expanded {
        padding: 0;
        margin: 0;

        .dropdown-button {
            border-color: global.$primary-color !important;
            background-color: global.$off-white !important;
        }

        ul {
            max-height: 270px !important;
            box-shadow: 0 10px 30px 0 rgba($color: #000000, $alpha: 0.35);
            border: 1px solid global.$mid-grey-1;
        }
    }

    ul {
        button {
            background-color: global.$off-white !important;
            border-width: 0px !important;
        }
    }

    .font-button {
        font-size: 1.2rem;;
    }

    h2 {
        margin-bottom: 0.5rem;
    }
}

.font-popup {
    max-width: 720px;
}
