@use "../../scss-fragments/global";
@use "../../scss-fragments/forms";

.arcade-data-popup {
    width: calc(100vw - 10rem);
    max-width: calc(100vw - 10rem);
    height: calc(100vh - 10rem);
    max-height: calc(100vh - 10rem);
    font-weight: 500;
}

.arcade-data-scrolling-panel {
    height: calc(100vh - 25rem);
    max-height: calc(100vh - 25rem);
    width: 100%;
    max-width: 100%;
    padding: 1rem;
    overflow-y: scroll;

    .activations-list {
        margin-top: 1rem;
    }
}

.arcade-data-box {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 1.5rem 2rem;
    border-radius: global.$panel-border-radius;
    border-width: 2px;
    border-style: solid;
    border-color: global.$mid-grey-2;
    background-color: global.$panel-body-bg;
    margin: 0;
    width: 100%;

    .sticky {
        position: sticky;
        top: 0;
        z-index: 999;
        background-color: global.$panel-body-bg;
        width: 100%;
    }

    .grid {
        display: grid;

        grid-template-columns: 18rem 20rem auto 8rem;
        gap: 0 0.5rem;

        align-items: center;

        .header {
            @include global.unselectable;

            position: sticky;
            top: 0;
            background-color: global.$panel-body-bg;
            padding: 0.25rem 0.25rem 0.25rem 0.25rem;
            width: 100%;
            z-index: 999;
            
            white-space: nowrap;
        }

        .pp-style {
            border-color: global.$panel-body-bg;
        }

        .data {
            padding: 0.25rem 0.25rem 0.25rem 0.25rem;
            font-weight: 600;

            a {
                color: global.$twitch-purple;

                &:visited {
                    color: global.$twitch-purple;
                }

                &:hover {
                    color: global.$twitch-purple;
                }

                &:active {
                    color: global.$twitch-purple;
                }
            }
        }

        hr {
            margin-top: -1rem;
            margin-bottom: 0.5rem;
            grid-column-start: 1;
            grid-column-end: 4;
            color: global.$mid-grey-1;
        }

        .full-width {
            grid-column-start: 1;
            grid-column-end: 3;
        }

        .centered {
            justify-self: center;
            text-align: center;
        }

        .right {
            justify-self: end;
            text-align: right;
        }

        &.prizes {
            grid-template-columns: 18rem 40% auto 8rem;
        }

        &.stream-markers {
            grid-template-columns: 18rem 20rem 26rem auto;
        }

        &.game-setup {
            text-transform: capitalize;
            grid-template-columns: 16rem auto;
        }
    }

    .alt-bg {
        background-color: global.$primary-light-color;
    }

    .opt-in-scroll-container {
        width: 100%;
        height: 30rem;
        max-height: 30rem;
        overflow-y: scroll;
        padding-right: 1rem;
    }

    .under-list {
        font-weight: 400 !important;
        margin-bottom: 0.5rem;
    }

    .local-time-list {
        font-weight: 400 !important;
    }

    &.no-box {
        border: none;
    }
}

.arcade-data-row {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    gap: 0.5rem !important;
    font-size: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .data {
        font-weight: 600;
        color: global.$twitch-purple;
    }

    .title {
        font-weight: 400;
        color: global.$label-color;
    }
}

.arcade-data-heading-row-block {
    display: flex;
    flex-shrink: 1;
    gap: 0.5rem !important;

    font-size: 1rem;
    font-weight: 400;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
        color: global.$light-text-color;
    }

    color: global.$primary-color;
}
