@use "../../scss-fragments/global";

.one-custom-font {
    border: 2px solid global.$twitch-purple;
    border-radius: 0.5rem;
    background-color: global.$primary-light-color;
    padding: 0.5rem;

    &.clickable {
        cursor: pointer;
    }
}
