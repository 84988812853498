@use '../../scss-fragments/global';

.little-red-text-button {
    @include global.button-style;
    background-color: global.$twitch-purple;
    color: #FFF;
    width: 100%;
    border-width: 0;
    border-radius: 0.5rem;
    margin: 0 !important;
    padding: 0.25rem 1rem !important;
    cursor: pointer;
}

.back-to-overview {
    font-weight: 500;
    color: global.$primary-color;

    a, a:hover, a:active, a:visited {
        font-weight: 500;
        color: global.$primary-color;
        text-decoration: none;
    }  
}

.ms-tabbed-panel {
    height: calc(100vh - 23rem);
    min-height: calc(100vh - 23rem);
    max-height: calc(100vh - 23rem);
}

.ms-an-full-scroll-area {
    height: calc(100vh - 26rem);
    min-height: calc(100vh - 26rem);
    max-height: calc(100vh - 26rem);
    padding-bottom: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.top-info {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    flex-grow: 1;
    width: 100%;

    white-space: nowrap;
    font-weight: 600;

    button {
        min-width: 12rem;
        max-width: 12rem;
        margin: 0 !important;
    }

    .standard-button {
        height: 2.8rem;
    }

    .right-status-block {
        margin-top: -0.5rem;
        margin-bottom: -0.5rem;
    }

    .status-dot {
        margin-right: 0.5rem;
    }

    .active {
        color: global.$active-color;
    }

    .inactive {
        color: global.$error-dark-color;
    }

    .no-gap {
        gap: 0;
    }

    .back-button {
        @include global.round-button-style;
        width: 4.5rem;
        height: 4.5rem;
    }

    h1 {
        color: global.$dark-grey-2;
        margin: 0;
    }
    h2 {
        color: global.$mid-grey-1;
        font-size: 1.7rem;
        margin: 0;
    }

    .big-status-light {
        @include global.round-button-style;
        border-width: 0;
        color: #FFF;
        width: 3rem;
        height: 3rem;
        margin-left: 1rem;
        cursor: default;
    }

    .active {
        background-color: global.$success-color;
    }

    .inactive {
        background-color: global.$error-dark-color;
    }

    .no-wrap {
        white-space: nowrap;
    }
}

.split-content {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    width: 100%;
    /* font-weight: 600; */
    color: global.$label-color;

    .underlined-panel-heading {
        white-space: nowrap;

        button {
            max-width: fit-content;
            margin: 0;
        }
    }

    h4 {
        color: global.$label-color;
    }

    .column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-grow: 1;
        width: 100%;
    }

    .shrink-column {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        flex-shrink: 1;
        width: fit-content;
        min-width: 38rem;
    }

    .scroll-area {
        height: 48rem;
        min-height: 48rem;
        max-height: 48rem;
        padding-bottom: 1rem;
        padding-right: 1rem;
        overflow-y: scroll;
    }

    .scroll-area._42 {
        height: calc(100vh - 37rem);
        min-height: calc(100vh - 37rem);
        max-height: calc(100vh - 37rem);
    }

    .tr-scroll-area {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        height: calc(100vh - 45rem);
        min-height: calc(100vh - 45em);
        max-height: calc(100vh - 45rem);
        padding-bottom: 1rem;
        padding-right: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .ms-full-scroll-area {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        height: calc(100vh - 31rem);
        min-height: calc(100vh - 31rem);
        max-height: calc(100vh - 31rem);
        padding-bottom: 1rem;
        padding-right: 1rem;
        overflow-y: scroll;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }

    .stream-time {
        margin-bottom: 1rem;
    }

    .timer-data {
        display: flex;
        flex-direction: column;
        gap: 0;

        p {
            margin: 0;
        }

        h4 {
            margin: 0;
        }

        .time {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            align-items: baseline;
        }
    }

    .headed-panel {
        margin-bottom: 1.5rem;
    }

    .radio-group {
        gap: 2rem;
    }

    button {
        max-width: 40rem;
    }
}

.an-tr-scroll-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    height: fit-content;
    min-height: 30rem;
    max-height: 30rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.an-tu-scroll-area {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    height: fit-content;
    min-height: 26rem;
    max-height: 26rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;
    text-overflow: ellipsis;
}

.ad-bug-position-holder {
    position: relative;
    width: 100%;
    height: 5rem;

    .bg {
        position:absolute;
        left: 0;
        top: 0;
        border-width: 0;
        border-style: solid;
        border-radius: 1rem;
        width: 100%;
        height: 100%;
    }

    .pos {
        position: absolute;
        border-width: 0;
        border-style: solid;
        border-radius: 1rem;
        width: 20%;
        height: 30%;
    }

    .top {
        top: 0.5rem;
    }

    .bottom {
        bottom: 0.5rem;
    }

    .left {
        left: 0.5rem;
    }

    .right {
        right: 0.5rem;
    }
}

.abpos-selected {
    .bg {
        background-color: global.$primary-light-color;
    }
    .pos {
        background-color: global.$primary-mid-color;
    }
}

.abpos-unselected {
    .bg {
        background-color: global.$secondary-bg-color;
    }
    .pos {
        background-color: global.$secondary-color;
    }
}

.stream-not-started {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 21rem;
    border-width: 0;
    border-style: solid;
    border-radius: 1rem;
    background-color: global.$secondary-bg-color;
    color: global.$light-text-color;

    margin-bottom: 1rem;

    .pause-button {
        @include global.round-button-style;
        margin: auto;
        color: global.$light-text-color;
        border-color: global.$light-text-color;
        cursor: default;
    }
}

.start-stream-btn {
    width: 26rem !important;
    height: 6rem !important;
    margin-top: 2rem !important;
    margin-bottom: 3rem !important;
    font-size: 2rem;
    font-weight: 600;

    /* border-width: 2px !important;
    border-color: global.$primary-color !important;
    color: global.$primary-color !important;
    background-color: #FFF !important; */
}

.selected-feature {
    border-width: 2px;
    border-color: global.$primary-color;
}

.disruptor-triggers-list {
    max-height: 0px;
    height: fit-content;
    margin-top: -1.5rem;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s;
}

.dtl-expanded {
    margin-top: 0;
    opacity: 1;
    max-height: fit-content;
}

.live-preview-toggle-content {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex-grow: 1;
    font-weight: 400;
    margin: -0.7rem 0;
    align-self: center;
    h5.live {
        margin: 0;
        color: #FFF !important;
    }
    h5.preview {
        margin: 0;
        color: #FFF !important;
    }
    h5.disabled {
        margin: 0;
        color: global.$mid-grey-1 !important;
    }
}

.force-stop-btn {
    margin: 0 !important;
    height: 2.8rem !important;
    background-color: #C30052 !important;
    border-color: #C30052 !important;
    font-weight: 600;
    font-size: 1.2rem;
}

.force-stop-text {
    font-weight: 500;
    color: global.$dark-grey-3;
}

.red-force-stop-text {
    font-weight: 600;
    font-size: 1.2rem;
    color: global.$dark-grey-3;
    h2 {
        color: #C30052 !important;
        margin: 0;
    }
    svg {
        color: #C30052 !important;
    }
    .standard-button {
        background-color: #C30052 !important;
        border-color: #C30052 !important;
        max-width: fit-content;
        font-weight: 500;
    }
    text-align: center;

    input {
        max-width: 20rem;
    }
}

.feature-controls-scroll-area {
    height: 48rem;
    min-height: 48rem;
    max-height: 48rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    overflow-y: scroll;
}
.user-profile-pic-small
{
    max-width: 1.4rem;
    max-height: 1.4rem;
    border-radius: 50rem;
    margin-right:.7rem;
}
.connectedChannelList
{
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    flex-grow: 1;
    width: 100%;
}

.connectedChannel
{
    margin-top:8px;
    overflow: hidden;
    font-weight: 500;
    border-radius: 50em;
    border: 2px solid global.$twitch-purple;
    color: global.$twitch-purple;
    padding:5px 10px 5px 5px;
    font-size:.8rem;
}

.spacer {
    margin-top: 1rem;
}
