@use "../../scss-fragments/global";

.popup-panel-holder {
    display: flex;
    align-content: center;
}

.popup-panel {
    @include global.panel-style;
    
    position: relative;
    width: 90%;
    max-width: 500px;
    margin: auto;

    max-height: 90vh;

    overflow: auto;

    h2 {
        margin-bottom: 2rem;
    }

    .close-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 3rem;
        height: 3rem;
        border-radius:100em;
        border-width: 2px;
        border-style: solid;
        border-color: global.$twitch-purple;
        color: global.$twitch-purple;

        z-index:1000;

        cursor: pointer;

        .icon {
            height: 1.2rem;
        }
    }

    .scroll-container {
        max-height: calc(60vh - 13.5rem);
        overflow-y: auto;
    }
}
.wide-popup {
    width:90%;
    max-width: 750px !important;
}
.Toastify__toast-container.Toastify__toast-container--bottom-left {
    z-index:20000;
}
