@use "../../../../scss-fragments/global";

.randomiser-status {
    .status-main-header {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: 700;
        padding: 0;

        &.active {
            /* background-color: global.$success-dark-color; */
            color: global.$success-dark-color;
        }

        &.inactive {
            /* background-color: global.$error-dark-color; */
            color: global.$error-dark-color;
        }
    }
}

.one-randomiser-option {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    border: 1px solid global.$twitch-purple;
    background-color: global.$primary-light-color;
    border-radius: 0.5rem;
    padding: 0.5rem;

    color: global.$body-copy-color;

    &.spent {
        border-color: global.$warning-color;
        background-color: global.$warning-light-color;
        color: global.$warning-color;

        .label {
            color: global.$warning-color;
        }
    }

    &.not-included {
        border-color: global.$mid-grey-2;
        background-color: transparent;
        color: global.$mid-grey-1 !important;

        .label {
            color: global.$mid-grey-1 !important;
        }
    }

    &.changed {
        border-color: global.$error-dark-color;
    }

    .dark-text {
        color: global.$body-copy-color;
    }

    &.preview {
        border-color: global.$mid-grey-1;
        background-color: global.$mid-grey-2;

        .status {
            color: global.$twitch-purple;
            text-transform: uppercase;
        }
    }

    &.spinning {
        border-color: global.$success-dark-color;
        background-color: global.$success-light-color;
        
        .status {
            color: global.$success-dark-color;
            text-transform: uppercase;
        }
    }

    &.stopping {
        border-color: global.$warning-dark-color;
        background-color: global.$warning-light-color;
        
        .status {
            color: global.$warning-dark-color;
            text-transform: uppercase;
        }
    }

    &.stopped {       
        .status {
            color: global.$error-dark-color;
            text-transform: uppercase;
        }
    }
}

.randomiser-option-list-box {
    max-height: 550px;
    overflow-y: scroll;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
}

.randomiser-prev-results-list-box {
    max-height: 550px;
    overflow-y: scroll;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
}

.small-ropt-control-btn {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border: 0px solid transparent;
    border-radius: 0.5rem;
    background-color: global.$primary-color;
    color: #fff;
    text-align: center;

    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }

    &.red {
        background-color: global.$error-dark-color;
    }
    &.green {
        background-color: global.$success-dark-color;
    }
    &.yellow {
        background-color: global.$warning-dark-color;
    }
    &.grey {
        background-color: global.$dark-grey-3;

        &:hover {
            cursor: not-allowed;
        }
    }
}